import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Joggers() {
  return (
    <Layout>
      <GetItems search="Women's Joggers" title="Women's Joggers" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./womens/joggers'} title="STM Quality Women's Joggers: Positivity and Awareness" description={"Shopping for women's joggers to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)